// @flow
import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';

import styles from './BoxIndex.module.scss';

type Props = {
  title: string,
  style?: Object,
  anchorId: string,
  mainClassName?: string,
  children: React$Node,
  isGray: boolean,
  linkText?: string,
  linkTo?: string,
  linkAction?: Function,
  linkType?: string,
  sectionStyle?: Object,
  wrapperClassName?: string,
  titleStyle?: Object,
};

const BoxIndex = ({
  title,
  style,
  anchorId,
  mainClassName,
  children,
  isGray,
  linkText,
  linkTo,
  linkAction,
  linkType,
  sectionStyle,
  wrapperClassName,
  titleStyle,
}: Props) => {
  return (
    <div
      id={anchorId}
      style={style}
      className={cx(
        styles.wrapper,
        isGray ? styles.isGray : '',
        wrapperClassName
      )}
    >
      <section className={styles.section} style={sectionStyle}>
        <main className={cx(styles.main, mainClassName)}>
          {title && (
            <h2 className={styles.h2} style={titleStyle}>
              {title}
            </h2>
          )}
          {children}
        </main>
        {linkText && (
          <Link
            className={`btn long ga_click_picup_button_moreshop ${
              linkType || ''
            }`}
            to={linkTo}
            onClick={linkAction}
          >
            {linkText}
          </Link>
        )}
      </section>
    </div>
  );
};

BoxIndex.defaultProps = {
  mainClassName: '',
  titleClassName: '',
  style: {},
  isGray: false,
  linkText: '',
  linkTo: '/shop',
  linkAction: undefined,
  linkType: 'secondary',
  sectionStyle: {},
  wrapperClassName: undefined,
  titleStyle: {},
};

export default BoxIndex;
